<template>
  <div class="printBodyTotal">
    <kr-print-designer
      :temp-value="value"
      :widget-options="widgets"
      :lodop-license="licenseInfo"
      @save="handleSave"
      class="printBody"
    />
  </div>
</template>
<script>
import { selectDeliveryOrderInfo } from "@/api/storage/deliveryOrder";
export default {
  data() {
    return {
      value: {
        title: "出库单",
        width: 750,
        height: 650,
        pageWidth: 750,
        pageHeight: 650,
        tempItems: [
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 770,
            height: 25,
            left: 0,
            top: 10,
            title: "公司名称",
            value: "绍兴上虞新越医疗器械有限公司产品出货单 ",
            defaultValue: "绍兴上虞新越医疗器械有限公司产品出货单",
            name: "",
            style: {
              FontSize: 15,
              Bold: true,
              Alignment: "center",
            },
            uuid: "7f7b0b45da",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 50,
            title: "No",
            value: "No：",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a141",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 95,
            top: 50,
            title: "No",
            value: "{ No } ",
            defaultValue: "",
            name: "shippingOrderNo",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a142",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 275,
            top: 50,
            title: "出货日期",
            value: "出货日期： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a143",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 335,
            top: 50,
            title: "出货日期",
            value: "{出货日期} ",
            defaultValue: "shippingDate",
            name: "shippingDate",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a244",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 455,
            top: 50,
            title: "产地",
            value: "产地： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a172",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 495,
            top: 50,
            title: "产地",
            value: "{产地} ",
            defaultValue: "producingArea",
            name: "producingArea",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a144",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 80,
            title: "客户名称",
            value: "客户名称： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a145",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 400,
            height: 20,
            left: 130,
            top: 80,
            title: "客户名称",
            value: "{客户名称} ",
            defaultValue: "customerName",
            name: "customerName",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a156",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 110,
            title: "收货地址",
            value: "收货地址： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a146",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 130,
            top: 110,
            title: "收货地址",
            value: "{收货地址} ",
            defaultValue: "收货地址",
            name: "receivingAddress",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a147",
          },
          {
            type: "braid-table",
            title: "产品明细",
            isEdit: 0,
            dragable: true,
            resizable: true,
            width: 755,
            height: 500,
            left: 5,
            top: 136,
            title: "出库商品明细",
            value: "{ details } ",
            defaultValue: [
              {
                productName: "苹果ipone11pro",
                skuName: "iphone11pro256g",
                specModel: "165L",
                quantity: 3,
                snCode: "[SPP1Y79G1]",
                price: "23.00",
              },
              {
                productName: "苹果ipone11pro",
                skuName: "iphone11pro124g",
                specModel: "165L",
                quantity: 3,
                snCode: "[SPP1Y79G1]",
                price: "12.00",
              },
            ],
            tabelHtml: "",
            columnsAttr: [
              {
                title: "产品编号",
                value: "{产品编号}",
                name: "productNo",
              },
              {
                title: "名称",
                value: "{名称}",
                name: "productName",
              },
              {
                title: "规格型号",
                value: "{规格型号}",
                name: "spec",
              },
              {
                title: "批号",
                value: "{批号}",
                name: "batchNo",
              },
              {
                title: "失效期",
                value: "{失效期}",
                name: "expirationDate",
              },
              {
                title: "单位",
                value: "{单位}",
                name: "unit",
              },
              {
                title: "数量",
                value: "{数量}",
                name: "quantity",
              },
              {
                title: "单价",
                value: "{单价}",
                name: "unitPrice",
              },
              {
                title: "金额",
                value: "{金额}",
                name: "amount",
              },
              // {
              //   title: "生产许可证",
              //   value: "{生产许可证}",
              //   name: "productionLicense",
              // },
              // {
              //   title: "产品注册证",
              //   value: "{产品注册证}",
              //   name: "productRegistration",
              // },
            ],
            selectCol: [
              "amount",
              "batchNo",
              "expirationDate",
              "orderNo",
              "productName",
              "quantity",
              "spec",
              "unit",
              "unitPrice",
              "productionLicense",
              "productRegistration",
            ],
            columns: [
              {
                title: "产品编号",
                value: "{产品编号}",
                name: "productNo",
              },
              {
                title: "名称",
                value: "{名称}",
                name: "productName",
              },
              {
                title: "规格型号",
                value: "{规格型号}",
                name: "spec",
              },
              {
                title: "批号",
                value: "{批号}",
                name: "batchNo",
              },
              {
                title: "失效期",
                value: "{失效期}",
                name: "expirationDate",
              },
              {
                title: "单位",
                value: "{单位}",
                name: "unit",
              },
              {
                title: "数量",
                value: "{数量}",
                name: "quantity",
              },
              {
                title: "单价",
                value: "{单价}",
                name: "unitPrice",
              },
              {
                title: "金额",
                value: "{金额}",
                name: "amount",
              },
              // {
              //   title: "生产许可证",
              //   value: "{生产许可证}",
              //   name: "productionLicense",
              // },
              // {
              //   title: "产品注册证",
              //   value: "{产品注册证}",
              //   name: "productRegistration",
              // },
            ],
            name: "orderDetailList",
            style: {
              zIndex: 0,
              Alignment: "center",
              FontSize: 9,
              FontColor: "#000000",
              BorderColor: "#000000",
              AutoHeight: true,
              BottomMargin: 0,
            },
            uuid: "8f1ee2cff3",
          },
          // 存储条件
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 390,
            title: "存运条件",
            value: "存运条件：常温",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a327",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 500,
            height: 20,
            left: 170,
            top: 390,
            title: "存运条件",
            value: "",
            defaultValue: "存运条件：常温",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a128",
          },

          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 415,
            title: "备注",
            value: "备注： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a326",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 500,
            height: 20,
            left: 170,
            top: 415,
            title: "备注",
            value: "{备注} ",
            defaultValue: "备注",
            name: "remarks",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a129",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 75,
            top: 445,
            title: "仓库员(发货员)",
            value: "仓库员(发货员)： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a248",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 170,
            top: 445,
            title: "仓库员(发货员)",
            value: "{仓库员(发货员)} ",
            defaultValue: "仓库员(发货员)",
            name: "sender",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a219",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 268,
            height: 20,
            left: 275,
            top: 445,
            title: "审核人",
            value: "审核人： ",
            defaultValue: "",
            name: "",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a249",
          },
          {
            type: "braid-txt",
            isEdit: 1,
            dragable: true,
            resizable: true,
            width: 300,
            height: 20,
            left: 335,
            top: 445,
            title: "审核人",
            value: "{审核人} ",
            defaultValue: "审核人",
            name: "reviewer",
            style: {
              zIndex: 0,
              FontSize: 9,
              FontColor: "#  000000",
              Bold: false,
              Italic: false,
              Underline: false,
              Alignment: "left",
              ItemType: 1,
            },
            uuid: "a77126a139",
          },
        ],
        default: true,
      },
      widgets: [
        {
          type: "braid-txt",
          isEdit: false,
          title: "自定义文本",
          value: "自定义文本",
          defaultValue: "自定义文本",
        },
        {
          type: "braid-txt",
          isEdit: true,
          title: "公司名称",
          value: "绍兴上虞新越医疗器械有限公司产品出货单",
          //   value: "{公司名称}",
          defaultValue: "绍兴上虞新越医疗器械有限公司产品出货单",
          name: "companyName",
          width: "450",
          top: "18",
          left: "160",
          style: {
            FontSize: 15,
            Bold: true,
            Alignment: "center",
          },
        },
        {
          type: "bar-code",
          title: "订单编号（条码）",
          value: "{orderNumber}",
          defaultValue: "1234567890",
          name: "orderNumber",
          lodopStyle: { QRCodeVersion: "1", QRCodeErrorLevel: "L" },
        },
        {
          type: "braid-html",
          title: "分页",
          value: "{第##页/共##页}",
          defaultValue:
            "<font ><span tdata='pageNO'>第##页</span>/<span tdata='pageCount'>共##页</span></font>",
        },
        {
          type: "braid-image",
          title: "logo",
          value:
            "http://192.168.7.229/syy/file/files/view/5f9fb42dabebb4049ffbd019",
        },
        {
          type: "braid-table",
          title: "产品明细",
          name: "details",
          value: "{details}",
          width: "635",
          top: "116",
          defaultValue: [
            {
              productName: "苹果ipone11pro",
              skuName: "iphone11pro256g",
              specModel: "165L",
              quantity: 3,
              price: "12.00",
            },
            {
              productName: "苹果ipone11pro",
              skuName: "iphone11pro124g",
              specModel: "165L",
              quantity: 3,
              price: "12.00",
            },
          ],
          columnsAttr: [
            {
              title: "产品编号",
              value: "{产品编号}",
              name: "productNo",
            },
            {
              title: "名称",
              value: "{sku名称}",
              name: "skuName",
            },
            {
              title: "规格型号",
              value: "{规格型号}",
              name: "specModel",
            },
            {
              title: "批号",
              value: "{数量}",
              name: "quantity",
            },
            {
              title: "失效期",
              value: "{失效期}",
              name: "expirTime",
            },
            {
              title: "单位",
              value: "{单位}",
              name: "unit",
            },
            {
              title: "数量",
              value: "{数量}",
              name: "num",
            },
            {
              title: "单价",
              value: "{单价}",
              name: "singlePrice",
            },
            {
              title: "金额",
              value: "{金额}",
              name: "amount",
            },
          ],
        },
      ],
      licenseInfo: "",
      valueData: {},
    };
  },
  methods: {
    handleSave() {
      this.$lodop.preview(this.value, [this.valueData]);
    },
    selectDeliveryOrderInfoById(id) {
      selectDeliveryOrderInfo(id).then((res) => {
        let tempData = new Map();
        res.orderDetailList.map((item) => {
          if (!tempData.has(item.productName)) {
            tempData.set(item.productName, {
              pro: item.productionLicense,
              dut: item.productRegistration,
            });
          }
        });
        let itemArr = [];
        let exteaData = false;
        let exteaData2 = false;
        tempData.forEach(function (value, key) {
          if (key.indexOf("手术电极") != -1 && !exteaData) {
            exteaData = true;
            res.extraData1 = value.pro + "/" + value.dut;
            itemArr.push(
              {
                type: "braid-txt",
                isEdit: 1,
                dragable: true,
                resizable: true,
                width: 368,
                height: 20,
                left: 60,
                top: 345,
                title: "一次性使用手术电极生产许可证/产品注册证",
                value: "一次性使用手术电极生产许可证/产品注册证：",
                defaultValue: "",
                name: "",
                style: {
                  zIndex: 0,
                  FontSize: 9,
                  FontColor: "#  000000",
                  Bold: false,
                  Italic: false,
                  Underline: false,
                  Alignment: "left",
                  ItemType: 1,
                },
                uuid: "a77126a286",
              },
              {
                type: "braid-txt",
                isEdit: 1,
                dragable: true,
                resizable: true,
                width: 500,
                height: 20,
                left: 300,
                top: 345,
                title: "一次性使用手术电极生产许可证/产品注册证",
                value: "{一次性使用手术电极生产许可证/产品注册证}",
                defaultValue: "一次性使用手术电极生产许可证/产品注册证",
                name: "extraData1",
                style: {
                  zIndex: 0,
                  FontSize: 9,
                  FontColor: "#  000000",
                  Bold: false,
                  Italic: false,
                  Underline: false,
                  Alignment: "left",
                  ItemType: 1,
                },
                uuid: "a77126a159",
              }
            );
          }
          if (key.indexOf("中性电极") != -1 && !exteaData2) {
            exteaData2 = true;
            res.extraData2 = value.pro + "/" + value.dut;
            itemArr.push(
              {
                type: "braid-txt",
                isEdit: 1,
                dragable: true,
                resizable: true,
                width: 368,
                height: 20,
                left: 60,
                top: 375,
                title: "一次性使用中性电极生产许可证/产品注册证",
                value: "一次性使用中性电极生产许可证/产品注册证：",
                defaultValue: "",
                name: "",
                style: {
                  zIndex: 0,
                  FontSize: 9,
                  FontColor: "#  000000",
                  Bold: false,
                  Italic: false,
                  Underline: false,
                  Alignment: "left",
                  ItemType: 1,
                },
                uuid: "a77126a286",
              },
              {
                type: "braid-txt",
                isEdit: 1,
                dragable: true,
                resizable: true,
                width: 500,
                height: 20,
                left: 300,
                top: 375,
                title: "一次性使用中性电极生产许可证/产品注册证",
                value: "{一次性使用中性电极生产许可证/产品注册证}",
                defaultValue: "一次性使用中性电极生产许可证/产品注册证",
                name: "extraData2",
                style: {
                  zIndex: 0,
                  FontSize: 9,
                  FontColor: "#  000000",
                  Bold: false,
                  Italic: false,
                  Underline: false,
                  Alignment: "left",
                  ItemType: 1,
                },
                uuid: "a77126a159",
              }
            );
          }
        });
        this.value.tempItems.push(...itemArr);
        this.valueData = res;
      });
    },
  },

  mounted() {
    let id = this.$route.query.id;
    this.selectDeliveryOrderInfoById(id);
  },
};
</script>
<style lang="css">
.printBody .kr-designer-tool_bar button[type="button"]:last-child {
  display: none;
}
.printBody .kr-designer-tool_bar button[type="button"]:first-child {
  font-size: 35px;
}
</style>
<style lang="css" scoped>
@import url("./kr-print-designer2.css");
</style>
